export type ErrorProps = {
  error: Error
}

export function SomethingWrong({ error }: ErrorProps) {
  return (
    <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
      <div className="flex h-full w-full flex-1 flex-col items-center justify-center bg-black font-semibold text-white">
        <h2 className="mt-16 text-4xl">Oops!</h2>
        <span className="mt-10 text-2xl uppercase tracking-tighter">Something went wrong!</span>
        {
          <div className="mt-10 whitespace-pre-line text-center text-sm text-red-600">
            {error.stack}
          </div>
        }
      </div>
    </div>
  )
}
