export const DEFAULT_TITLE = 'Artisan Cove Live/Work'
export const DEFAULT_DESCRIPTION =
  'Artisan Cove is a live/work community located in Richmond, California near the historic Richmond Marina.'
export const DEFAULT_URL = 'https://artisancovelivework.com'
export const DEFAULT_OG_IMAGE = `${DEFAULT_URL}/images/default-og.jpg`
export const DEFAULT_TWITTER_IMAGE = `${DEFAULT_URL}/images/default-twitter.jpg`

export const DEFAULT_META = {
  title: DEFAULT_TITLE,
  description: DEFAULT_DESCRIPTION,
  'og:title': DEFAULT_TITLE,
  'og:url': DEFAULT_URL,
  'og:description': DEFAULT_DESCRIPTION,
  'og:image': DEFAULT_OG_IMAGE,
  'twitter:image': DEFAULT_TWITTER_IMAGE,
  'twitter:card': 'summary_large_image',
  'twitter:creator': '@artisancovelivework',
  'twitter:site': DEFAULT_URL,
  'twitter:title': DEFAULT_TITLE,
  'twitter:description': DEFAULT_DESCRIPTION
}
